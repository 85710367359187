import React from 'react';

const Welcome = ({ onStartGame, location }) => {
    return (
        <div className='fixed inset-0 z-[1100] flex items-center justify-center bg-gray-600/60'>
            <div className='bg-white p-6 rounded-xl shadow-inner max-w-md w-full max-h-[90vh] overflow-auto'>
                <h2 className='text-2xl font-semibold text-gray-800 mb-4'>Velkommen til Escape City Game {location}!</h2>
                <p className='text-lg font-normal text-gray-800 mb-4'>På dette spillet vil dere måtte følge kartet på denne nettsiden. Det vil dukke opp røde spørsmålstegn på kartet som dere må gå til, for å få opp et spørsmål. Dere vil da få svaralternativer. Svaret kan alltid finnes i virkligheten. Dette kan være tekst på bygg, farger, eller lignende. Om dere svarer riktig, vil dere få en bokstav. Jo flere spørsmål dere svarer riktig på, jo nærmere kommer dere det hemmelige ordet. Dere trenger dette ordet for å rømme. Om dere svarer feil på ett spørsmål kan dere fortsatt fortsette med spillet, men dere får ikke en bokstav for det spørsmålet.</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Det oppsto en hendelse i 1814 der Fredrikstads festning skulle settes på den hittils hardeste prøve. Under ledene militært hold var et svensk angrep ikke uventet. Festningens kommandant Niels Christian Hals ble beordret til å ta styring. Sommeren 1814 hadde svenskene en flåte liggende utenfor Strømstad, men generaladmiralen Johan Von Puke i ledelsen. 28. Juli trekker Johan ankeret og setter kursen mot Hvaler. Uten noe motstand ankrer svenskene Kråkerøy den 3. August. Der går de i land med en tropp på 600 mann og på 3 forskjellige steder.</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Like utenfor gamlebyen blir en liten styrke satt til land. Før Von Puke angrep, sendte han en henstilling til Hals om å overlevere festningen til kongen av Sverige, Kong Carl 13.</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Hals ga et negativt svar og innstilte seg på kamp. Kvelden 3. august åpner svenskene ild. Byen blir beskutt med både kuler og granater.</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Festningen svarte tilbake og fikk svenskene litt på avstand. Deretter stilner det.</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Tidlig morgen 4. august fyrte svenskene igjen og ikke lenge etter ble et hvitt parlamentært flagg heist. Hals ba om opphør og halvannen time senere var avtalen om festningens overgivelse undertegnet.</p>
                <p className='text-lg font-normal text-gray-800 mb-4'>Kommandant Niels Christian Hals ble satt som krigsfange.</p>
                <p className='text-lg font-bold text-gray-800 mb-4'>Hjelp Kommandanten å flykte fra Von Puke's menn! Dere har 2 timer på dere før svnenskene oppdager dere og burer dere inne bak lås å slå!</p>
                <p className='text-2lg font-bold text-gray-800 mb-4'>Lykke til!</p>
                <button className='py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600' onClick={onStartGame}>Start spillet!</button>
            </div>
        </div>
    )
}

export default Welcome