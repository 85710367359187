import React from 'react';
import MapComponent from './MapComponent';

const GameMap = () => {
    const zones = [
        { lat: '1.0', lng: '2.0' }
    ];

    const circleZone = {
        center: [59.218541, 10.922170],
        radius: 7.5
    };

    return (
        <div>
            <MapComponent zones={zones} circleZone={circleZone} />
        </div>
    )
}

export default GameMap