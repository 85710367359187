import React, { useState, useEffect } from 'react'

const QuizComponent = ({ text, question, answer, alternatives, closeQuiz, onAnswer }) => {
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isAnswered, setIsAnswered] = useState(false);
    const [shuffledOptions, setShuffledOptions] = useState([]);

    const handleAnswerSelect = (selected) => {
        setSelectedAnswer(selected);
        setIsAnswered(true);
        onAnswer(selected === answer);
    };

    const handleClose = () => {
        setSelectedAnswer(null);
        setIsAnswered(false);
        closeQuiz();
    };

    useEffect(() => {
        const options = [answer, ...alternatives];
        setShuffledOptions(options.sort(() => Math.random() - 0.5));
    }, [question, answer, alternatives]);

    return (
        <div className='fixed top-0 left-0 w-full h-full z-[1000] flex items-center justify-center bg-gray-600/60'>
            <div className='bg-white p-6 rounded-xl shadow-lg max-w-md w-full'>
                <h2 className='text-l font-normal text-gray-800 mb-4'>{text}</h2>
                <h2 className='text-2xl font-semibold text-gray-800 mb-4'>{question}</h2>
                <ul className='space-y-2'>
                    {shuffledOptions.map((option, index) => (
                        <li key={index} onClick={() => !isAnswered && handleAnswerSelect(option)} className={`cursor-pointer p-3 rounded-md text-gray-700 ${isAnswered ? (option === answer ? 'bg-green-200' : option === selectedAnswer ? 'bg-gray-200' : 'bg-red-200'): 'hover:bg-gray-100'}`}>
                            {option}
                        </li>
                    ))}
                </ul>
                {isAnswered && (
                    <div className={`mt-4 p-3 text-center rounded-md ${selectedAnswer === answer ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                        {selectedAnswer === answer ? 'Riktig!' : `Feil. Svaret er ${answer}`}
                    </div>
                )}
                <button onClick={handleClose} className='mt-4 py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600'>Lukk</button>
            </div>
        </div>
    )
}

export default QuizComponent