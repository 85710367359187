import L from 'leaflet';

export const customMarkerIcon = new L.Icon({
    iconUrl: '/images/marker-icon.png',
    iconSize: [40, 40],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [1, 1]
});

export const zoneIcon = new L.Icon({
    iconUrl: '/images/question-mark.png',
    iconSize: [20, 20],
    iconAnchor: [10, 10]
});

export const zoneIconAnswered = new L.Icon({
    iconUrl: '/images/home-button.png',
    iconSize: [20, 20],
    iconAnchor: [10, 10]
});