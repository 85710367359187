import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AccessPage = () => {
    const [code, setCode] = useState('');
    const navigate = useNavigate();

    const handleCodeSubmit = () => {
        // ADD Validation and check the code with your database
        // If valid, redirect to the game page
        navigate('/game');
    };

    return (
        <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center'>
            <h1 className='text-3xl font-bold text-gray-800 mb-4'>Welcome to Escape City Game</h1>
            <p className='text-gray-600 mb-2'>Buy a code and input here to play</p>
            <p className='text-gray-600 mb-2'>If disconnected, enter the same 5-digit code to continue playing</p>
            <a href='https://test-link-here.com' className='text-blue-500 hover:text-blue-700 mb-4'>Buy code here</a>
            <div className='bg-white shadow-md rounded-md px-8 pt-6 pb-8 mb-4'>
                <input
                    type='text'
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className='border border-gray-300 focus-within:border-blue-500 focus:ring-2 focus:ring-blue-200 text-gray-700 rounded-md py-2 px-4 block w-full appearance-none leading-normal mb-4'
                    placeholder='Enter your 5-digit code'
                />
                <button
                    onClick={handleCodeSubmit}
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                >
                    Start Game
                </button>
            </div>
        </div> 
    )
}

export default AccessPage