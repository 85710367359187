import React, { useState, useEffect, Fragment } from 'react';
import { MapContainer, TileLayer, Marker, Circle } from 'react-leaflet';
import { customMarkerIcon, zoneIcon, zoneIconAnswered } from '../helpers/mapIcons';
import { isUserInCircleZone } from '../helpers/checkUserPosition';
import { questionsGamlebyen } from '../helpers/CONSTANTS';
import CollectedLettersComponent from './CollectedLettersComponent';
import QuizComponent from './QuizComponent';
import 'leaflet/dist/leaflet.css';
import Welcome from './Welcome';

const MapComponent = () => {
    const sentence = 'BERØMMELSE';
    const [gameStarted, setGameStarted] = useState(false);
    const [collectedLetters, setCollectedLetters] = useState(Array(sentence.length).fill('_'));
    const [loading, setLoading] = useState(true);
    const [gameLocation, setGameLocation] = useState('gamlebyen');
    const [showNewLetterNotification, setShowNewLetterNotification] = useState(false);
    const [showBackpack, setShowBackpack] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [finalGuess, setFinalGuess] = useState('');
    const [isFinalPhase, setIsFinalPhase] = useState(false);
    const [activeQuiz, setActiveQuiz] = useState(null);
    const [answeredCount, setAnsweredCount] = useState(0);
    const [currentQuestionPosition, setCurrentQuestionPosition] = useState(0);
    const [circleZones, setCircleZones] = useState(questionsGamlebyen);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setCurrentPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
                setLoading(false);
            },
            (error) => {
                console.error('Geolocation error:', error);
                setLoading(false); 
            }
        );
    }, []);

    useEffect(() => {
        let watchId;
        let lastPosition = { lat: null, lng: null };
        let positionUpdateTimer = null;

        const updatePosition = (newPosition) => {
            setCurrentPosition(newPosition);
        };

        const onSuccess = (position) => {
            const newPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            if (!lastPosition.lat || Math.abs(newPosition.lat - lastPosition.lat) > 0.0001 || Math.abs(newPosition.lng - lastPosition.lng) > 0.0001) {
                clearTimeout(positionUpdateTimer);
                positionUpdateTimer = setTimeout(() => updatePosition(newPosition), 1000);
                lastPosition = newPosition;
            }
        };

        const onError = (error) => {
            console.error('Geolocation error:', error);
        };

        if (navigator.geolocation) {
            watchId = navigator.geolocation.watchPosition(onSuccess, onError, {
                enableHighAccuracy: true,
                timeout: 1000,
                maximumAge: 0
            });
        }

        return () => {
            if (watchId) {
                navigator.geolocation.clearWatch(watchId);
            }
            clearTimeout(positionUpdateTimer);
        };
    }, []);

    useEffect(() => {
        if (currentPosition) {
            const enteredZoneIndex = circleZones.findIndex(zone => isUserInCircleZone(currentPosition, zone) && !zone.answered);
            if (enteredZoneIndex !== -1) {
                setActiveQuiz(circleZones[enteredZoneIndex]);
            }
        }
    }, [currentPosition, circleZones]);

    useEffect(() => {
        const allAnswered = circleZones.every(zone => zone.answered);
        if (allAnswered) {
            setIsFinalPhase(true);
        }
    }, [answeredCount]);

    const closeQuiz = () => {
        setActiveQuiz(null);
    };

    const startGame = () => {
        setGameStarted(true);
    };

    const handleBackpackClick = () => {
        setShowBackpack(true);
        setShowNewLetterNotification(false);
    };

    const findNextUnansweredQuestion = () => {
        const nextQuestion = circleZones.find((zone, index) => index >= currentQuestionPosition && !zone.answered);
        return nextQuestion;
    };

    const onFinalGuessSubmit = (guess) => {
        if (guess.toLowerCase() === sentence.toLowerCase()) {
            alert('Det var riktig ord! Gratulerer!');
        } else {
            alert('Lol det var feil loser')
        }
    };

    const handleQuizAnswer = (isCorrect) => {
        const zoneIndex = circleZones.findIndex(zone => zone === activeQuiz);
        setAnsweredCount(answeredCount + 1);
        if (zoneIndex !== -1) {
            setCircleZones(currentZones => {
                const newZones = [...currentZones];
                newZones[zoneIndex].answered = true;
                if (isCorrect) {
                    newZones[zoneIndex].score += 1;
                }
                return newZones;
            });
            setCurrentQuestionPosition(currentPosition => currentPosition + 1);

            if (isCorrect) {
                const availablePositions = collectedLetters
                    .map((letter, index) => (letter === '_' ? index : null))
                    .filter(index => index !== null);

                if (availablePositions.length > 0) {
                    const randomPosition = availablePositions[Math.floor(Math.random() * availablePositions.length)];
                    const newLetters = [...collectedLetters];
                    newLetters[randomPosition] = sentence[randomPosition];
                    setCollectedLetters(newLetters);
                }

                setShowNewLetterNotification(true);
            }
        }
    };

    return (
        <>
        <button 
            className='fixed top-0 left-0 z-50 bg-red-500 px-6 py-4 text-3xl'
            onClick={() => {
                const nextQuestion = findNextUnansweredQuestion();
                if (nextQuestion) {
                    setCurrentPosition({
                        lat: nextQuestion.center[0],
                        lng: nextQuestion.center[1],
                    });
                    setActiveQuiz(nextQuestion);
                }
            }}
        >
            Set Position
        </button>

        <button onClick={handleBackpackClick} className='fixed top-1/2 left-5 z-[1000]'>
            <img src='/images/bag.png' alt='backpack' className='h-16 bg-blue-500/60 p-2 rounded-full inline-flex' />
            {showNewLetterNotification && (
                <span className='ml-4 bg-white p-2 rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'>
                    Du har fått en ny bokstav!
                </span>
            )}
        </button>

        <div className='fixed top-5 right-5 z-[1000] bg-white p-3 rounded-md shadow'>
            {answeredCount}/{circleZones.length} Spørsmål
        </div>

        {!loading && currentPosition ? (
            <MapContainer center={loading ? [51.505, -0.09] : [currentPosition.lat, currentPosition.lng]} zoom={17} style={{ height: '100vh', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                {!loading && currentPosition && (
                    <Marker position={[currentPosition.lat, currentPosition.lng]} icon={customMarkerIcon}></Marker>
                )}
                {circleZones.map((zone, index) => (
                    <Fragment key={index}>
                        {(zone.position === currentQuestionPosition || zone.answered) && (
                            <>
                                <Marker position={zone.center} icon={ zone.answered ? zoneIconAnswered : zoneIcon}></Marker>
                                <Circle
                                    center={zone.center}
                                    radius={zone.radius}
                                    pathOptions={{ color: zone.answered ? 'lightblue' : 'red', fillColor: zone.answered ? 'lightblue' : 'red', fillOpacity: 0.2 }}
                                />
                            </>
                        )}
                    </Fragment>
                ))}
            </MapContainer>
        ) : (
            <h1>Loading...</h1>
        )}

        {!gameStarted && (
            <Welcome onStartGame={startGame} location={gameLocation} />
        )}

        {activeQuiz && (
            <QuizComponent
                text={activeQuiz.text}
                question={activeQuiz.question}
                answer={activeQuiz.answer}
                alternatives={activeQuiz.alternatives}
                closeQuiz={closeQuiz}
                onAnswer={handleQuizAnswer}
            />
        )}

        {showBackpack && (
            <CollectedLettersComponent
                collectedLetters={collectedLetters}
                onClose={() => setShowBackpack(false)}
                finalGuess={finalGuess}
                onFinalGuessSubmit={onFinalGuessSubmit}
                isFinalPhase={isFinalPhase}
                setFinalGuess={setFinalGuess}
            />
        )}
        </>
    )
}

export default MapComponent