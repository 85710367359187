export const questionsGamlebyen = [
    { center: [59.202864, 10.957700], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Voldportbroen ble bygget i 1667! Broen var i de første årene pyntet med fargerike trefigurer. Broklappene ble hevet og senket når dagen var omme. Og denne broen var helt frem til 1906 den eneste inngangen fra innsiden av festningen.', question: 'For å heve disse broklappen trengtes det noen soldater. Hvor mange trengtes?', answer: '30', alternatives: ['27', '10', '53'], answered: false, score: 0, position: 0 },

    { center: [59.204188, 10.954336], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Dere står nå i Færgeportgaten 78b. Gaten har sitt navn etter hvor gaten tar dere. Gaten tar dere nemlig ned til færgeporten. Men bygningen dere står ved siden av er den gamle prestegården.', question: 'Se etter et navn inrisset i muren. Dette er navnet dere skal frem til, så hva heter denne bygningen?', answer: 'Tamburen', alternatives: ['Solgaard', 'Kommandantgården'], answered: false, score: 0, position: 1 },

    { center: [59.204511, 10.953519], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Kommisjonsgården. Denne gården ble bygget som et militært rettslokale og sto ferdig i 1834. kommisjonsgården er en etasjes murbygning i kjent empirestil.', question: 'Hvor mange rundbuede vinduer er det på midtpartier?', answer: '3', alternatives: ['2', '5', '8'], answered: false, score: 0, position: 2 },

    { center: [59.202860, 10.950793], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Andre ting som ligger på 3 er kjørboegården som ligger i 3. kvartal. Kjørboegården består av to bygninger oppført i teglstein. Kjørboegården var opprinnelig en privatbolig av Hans Kjørboe. Hans var en av byens mest velstående kjøpmenn, og mest kjent innen vinhandel. Huset ble kjøpt opp av militæretaten i senere tid og og skulle bli brukt som kommandantbolig, noe den ikke var egnet til. Bygningen ble deretter brukt som kornmagasin.', question: 'Når ble denne gården bygget?', answer: '1665', alternatives: ['1678', '1743', '1625'], answered: false, score: 0, position: 3 },

    { center: [59.203219, 10.952916], radius: 10, alertMessage: 'Nå er du ved statuen.', text: '', question: 'Hvem er dette?', answer: 'Kong Fredrik II', alternatives: ['Olav den hellige', 'Harald Hårfagre', 'Kong Harald'], answered: false, score: 0, position: 4 },

    { center: [59.203147, 10.952661], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Denne flotte gule bygningen dere står ovenfor nå er bygd av kjøpmann Søren Thygesen, og blitt bebodd av blant annet Brigade lege Abraham Siemers. Gården fikk sitt navn etter John Larsen som eide og bodde her på 1860 tallet. Han jobbet som høker (Kjøpmann med begrensede rettigheter) og solgte brennevin på dunker i kjelleren. ', question: 'Hva heter denne gule bygningen?', answer: 'Dunkejonsgården', alternatives: ['Solgaard', 'Kommandantgården', 'Hans Nielsens gård'], answered: false, score: 0, position: 5 },

    { center: [59.202105, 10.951540], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Tøihuset var landets største bygning i sin tid på 4000m2! Bygningen ble oppført i 1773 og var opprinnelig oppført med bordkledning bindingsverk. Men ble i senere tid forblendet med teglstein.', question: 'Porten fører dere til bakgården til tøihuset, hvilket år står det på porten?', answer: '1775', alternatives: ['1773', '1756', '1801'], answered: false, score: 0, position: 6 },

    { center: [59.201695, 10.951808], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Porten leder fra kirkegata til Tøihuset. Porten er bygget i fineste marmor og har Christians VII´s monogram risset inn mellom året det ble bygget.', question: 'Men hva ble den store bygningen brukt til?', answer: 'Oppbevaring av militært materiell', alternatives: ['For å signere viktige avtaler', 'Byens eneste brannstasjon'], answered: false, score: 0, position: 7 },

    { center: [59.204198, 10.954866], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Nå står dere ved siden av salamandergården. Etter en rekke branner ble denne gården bygget. Gården ble brukt til salmaker verksted og skredderverksted. Navnet på gården kommer opprinnelig fra en gammel tro der troen gikk ut på at salamandere var ildånder og beskyttet bygget for brann. Det sies at hvis man kaster en salamander i ilden skal ilden slokne.', question: 'Hva slags materiale er gården bygget på?', answer: 'Teglstein', alternatives: ['Betong', 'Trevirke'], answered: false, score: 0, position: 7 },

    { center: [59.204618, 10.953034], radius: 10, alertMessage: 'Nå er du ved statuen.', text: 'Bygget i 1727. Frederik IV´s monogram ser du over porten. Inntil midten av 1800-tallet var festningens porter stengt gjennom hele natten.', question: 'Når ble porten bygd?', answer: '1727', alternatives: ['1728', '1782', '1804'], answered: false, score: 0, position: 7 },
]