import { useEffect } from 'react';

export const isUserInCircleZone = (position, circleZone) => {
    const toRadians = (degree) => degree * (Math.PI / 180);
    const earthRadiusInMeters = 6371000;

    const dLat = toRadians(circleZone.center[0] - position.lat);
    const dLng = toRadians(circleZone.center[1] - position.lng);

    const lat1 = toRadians(position.lat);
    const lat2 = toRadians(circleZone.center[0]);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadiusInMeters * c;
    console.log('Distance to zone:', distance);
    return distance <= circleZone.radius;
};