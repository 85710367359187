import React from 'react'

const CollectedLettersComponent = ({ collectedLetters, onClose, finalGuess, setFinalGuess, onFinalGuessSubmit, isFinalPhase }) => {
    return (
        <div className='fixed top-0 left-0 w-full h-full z-[1100] flex items-center justify-center bg-gray-600/60'>
            <div className='bg-white p-6 rounded-xl shadow-lg max-w-md w-full'>
                <h2 className='text-2xl font-semibold text-gray-800 mb-4'>Samlet bokstaver</h2>
                <p className='text-lg font-normal text-gray-800 mb-4'>{collectedLetters.join(' ')}</p>
                {isFinalPhase && (
                    <>
                        <input
                            type='text'
                            value={finalGuess}
                            onChange={(e) => setFinalGuess(e.target.value)}
                            className='p-2 border-2 border-gray-300 rounded-md w-full mb-4'
                            placeholder='Skriv inn det hemmelige ordet...'
                        />
                        <button
                            onClick={() => onFinalGuessSubmit(finalGuess)}
                            className='py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600 mb-4'
                        />
                    </>
                )}
                <button onClick={onClose} className='py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600'>Lukk</button>
            </div>
        </div>
    )
}

export default CollectedLettersComponent