import React from 'react';

const Payment = () => {
    return (
        <div>
            <stripe-buy-button
                buy-button-id="buy_btn_1OYo5xDvIq8Qvkh21dBkGU2J"
                publishable-key="pk_test_51N00dYDvIq8Qvkh24THx0j9QzLrBrsfoSt6QWGmOQCn1tyyNYRlye5Aj8UcDR5J9WDW04Hh5aIFnRTdP1klrTtzw00YJNwDNUP"
                >
            </stripe-buy-button>
        </div>
    )
}

export default Payment